import React, { forwardRef, useRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import { Flex, Box, Text, Button } from 'rebass';
import CallUsNumber from '~common/molecules/CallUsNumber';
import ToggleSwitch from 'components/Elements/ToggleSwitch';
import Icon from '~common/atoms/Icon';
import Popover from 'components/Elements/Popover';
import { RatesToggle } from '~lib/CoverProducts';
import useElementWidth from '~lib/hooks/useElementWidth';
import JoinNowButton from './JoinNowButton';
import Loading from '~common/atoms/Loading';
import { pathOr } from '~lib/util';
import Link from '~common/atoms/Link';
import { useThemeUI } from 'theme-ui';
import useFiles from '~lib/hooks/sanity/useFiles';
import { useMobileScreen, usePortrait } from '~lib/responsive';
import DiscountBar from '~common/molecules/DiscountBar';

const PAYMENT_FREQUENCIES_PER = {
  WEEKLY: 'per week',
  MONTHLY: 'per month',
  QUARTERLY: 'per quarter',
  HALF_YEARLY: 'per 6 months',
  YEARLY: 'per year',
};

const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  margin: 0;
`;

const SelectedProducts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 20px 0;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 40px 0 10px 0;
  }
`;

const TileLabel = styled.span`
  display: block;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 1.31;
  text-transform: uppercase;
  color: #4a4a4a;
`;

const TileExcess = styled.div`
  margin-bottom: 22px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 1.2;
  color: #4a4a4a;
  height: 15px;

  &.show-mobile-block {
    text-align: left;

    @media screen and (max-width: 330px) {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    margin-left: 8px;
  }
`;

const TileDetails = styled.div`
  text-align: left;

  ${TileExcess} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const TileTitle = styled.h3`
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.colors[props.type] || props.theme.colors.dark};
  font-weight: 700;
`;

const TileDescription = styled.span`
  display: block;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.colors.dark};
`;

const TilePrice = styled.div`
  display: flex;
  flex-direction: row;
  justifycontent: flex-start;
  alignitems: center;
  margin-top: 24px;
  margin-bottom: 18px;
  font-size: 32px;
  color: ${props => props.theme.colors.dark};

  sup {
    font-size: ${props => props.theme.fontSizes[2]}px;
  }
`;

const TileLink = styled.a`
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 700;
  text-decoration: underline;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`;

const TileBox = styled.div`
  margin-top: 10px;
  border: 3px solid ${props => props.theme.colors.borders};
  background-color: ${props => props.theme.colors.white};
  padding: 24px;
  width: 100%;
  min-width: 230px;
  min-height: 231px;

  @media screen and (max-width: 992px) {
    width: 100%;
    min-width: 100%;
    min-height: auto;
    margin-top: 0;
    padding: 12px 20px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${TileLabel} {
      margin-bottom: 8px;
    }

    ${TileExcess}:empty {
      display: none !important;
    }
  }

  @media screen and (max-width: 330px) {
    flex-direction: column;
    align-items: space-between;
    justify-content: center;

    ${TileTitle},
    ${TileDescription},
    ${TileLabel} {
      text-align: center;
    }

    ${TilePrice} {
      margin-bottom: 10px;
    }
  }
`;

const tileWrapper = css`
  @media screen and (max-width: 992px) {
    width: 100%;
    ${TileBox} {
      border-bottom: none;
    }
  }
`;

const Tile = props => {
  return (
    <Box
      data-id={`product-tile-${props?.type}`}
      css={tileWrapper}
      className={props?.className}
    >
      <TileLabel
        data-id={`product-tile-${props?.type}-title`}
        className="hide-mobile"
      >
        {props?.label}
      </TileLabel>
      <TileBox>
        <TileDetails>
          <TileLabel className="show-mobile-block">{props?.label}</TileLabel>
          <TileTitle type={props?.type}>{props?.title}</TileTitle>
          {props?.description && (
            <TileDescription>{props?.description}</TileDescription>
          )}
          <TileExcess className="show-mobile-block">
            {props?.excess !== undefined
              ? `with ${props?.excess > 0 ? `$${props?.excess}` : 'no'} excess`
              : ''}
          </TileExcess>
        </TileDetails>

        {!!props?.price ? (
          <TilePrice>
            <NumberFormat
              value={props?.price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            {props?.asterisk ? <sup>*</sup> : null}
          </TilePrice>
        ) : (
          <>
            {props?.freeProductsEnabled && <TilePrice>FREE</TilePrice>}
            {!!props?.originalPrice && (
              <TileExcess>
                usually{' '}
                <NumberFormat
                  value={props?.originalPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />{' '}
                {PAYMENT_FREQUENCIES_PER[props?.paymentFrequency]}
              </TileExcess>
            )}
          </>
        )}

        {!props?.originalPrice && (
          <TileExcess className="hide-mobile">
            {props?.excess !== undefined
              ? `with ${props?.excess > 0 ? `$${props?.excess}` : 'no'} excess`
              : ''}
          </TileExcess>
        )}

        {props?.link && (
          <TileLink
            data-id={`see-details-${props?.type}`}
            className="hide-mobile"
            onClick={e => {
              e.preventDefault();
              props?.onDetails();
            }}
          >
            See details
          </TileLink>
        )}
      </TileBox>
    </Box>
  );
};

const ProductSpacer = styled.div`
  width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 30px auto 0 auto;

  > div {
    margin: 0 auto;
  }

  @media screen and (max-width: 992px) {
    position: relative;
    top: -11px;
    margin-top: 0;
    margin: 0 auto;
    flex: none;
    height: 0;
    width: 80px;
  }
`;

const Small = styled.small`
  display: inline;

  line-height: 26px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-style: italic;

  div {
    display: inline;
  }

  @media screen and (max-width: 992px) {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
`;

const paymentFrequencySelect = theme => css`
  margin: 0 0 0 10px;
  width: auto;
  appearance: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  color: ${theme.colors.white};
  font-size: 13px;
  line-height: 32px;
  font-weight: normal;
  height: 32px;
  padding: 0 26px 0 5px;
  -webkit-padding-start: 5px;
  -webkit-padding-end: 26px;
  background-color: ${theme.colors.background.primary};
  background-image: url('/images/chevron-down-white.svg');
  background-position: 96% 55%;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;

  option {
    position: relative;
  }

  &::-ms-expand {
    display: none;
  }
`;

const totalTile = theme => css`
  flex: 1;
  min-width: 318px;

  ${TilePrice} {
    color: ${theme.colors.white};
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    min-width: 100%;
    min-height: auto;
    margin-top: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${TileLabel} {
      margin-bottom: 14px;
      color: ${theme.colors.white};
    }
    ${TilePrice} {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 330px) {
    flex-direction: column;
    align-items: space-between;
    justify-content: center;

    ${TileTitle},
    ${TileLabel} {
      text-align: center;
    }

    ${TilePrice} {
      margin-bottom: 0;
    }
  }
`;

const totalTileInner = css`
  min-height: 231px;

  @media screen and (max-width: 992px) {
    min-height: auto;
    width: 100%;
  }
`;

const TotalTileContainer = styled(Flex)`
  background: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.white};
`;

const TotalTile = forwardRef((props, ref) => {
  const { theme } = useThemeUI();
  return (
    <Flex
      data-id={props['data-id']}
      ref={ref}
      css={totalTile(theme)}
      flexDirection="column"
    >
      <TileLabel data-id="product-tile-total-title" className="hide-mobile">
        Total Premium
      </TileLabel>
      <TotalTileContainer
        flexDirection="column"
        justifyContent="space-between"
        mt={{ xs: 0, md: '10px' }}
        css={totalTileInner}
      >
        <Box
          style={{ minHeight: '152px' }}
          pt={{ xs: '14px', md: '20px' }}
          px={{ xs: '20px', md: '32px' }}
        >
          {props?.selections?.hospital?.variant && (
            <Flex flexDirection="column">
              <TileLabel className="show-mobile-block">Total Premium</TileLabel>
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                align-items="center"
              >
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Text fontSize={1} pr={1}>
                    Hospital excess
                  </Text>
                  <Popover
                    position="top"
                    content="This is the amount you agree to pay before your health insurance starts to pay for your hospital costs. Excess is paid per admission up to the yearly excess you have chosen. Consider whether you will be able to manage the cost of the excess if you go to hospital. An excess on health insurance will reduce its cost."
                  >
                    <Icon
                      name="help-circled"
                      width="16px"
                      height="16px"
                      fill="rgba(255,255,255,0.5)"
                    />
                  </Popover>
                </Flex>

                <Flex
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ToggleSwitch
                    ariaLabel="Excess"
                    defaultValue={props?.selections?.hospital?.variant?.code}
                    values={
                      props?.variants?.length
                        ? props?.variants
                        : [
                            {
                              label: '$0',
                              value: props?.selections?.hospital?.variant?.code,
                            },
                          ]
                    }
                    disabled={props?.variants?.length <= 1}
                    onChange={props?.setSelected}
                    border={true}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            align-items="center"
            mt={{ xs: 0, lg: '15px' }}
          >
            <TilePrice data-id="total-quote-price">
              <NumberFormat
                value={props?.price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              <sup>*</sup>
            </TilePrice>

            {props?.price > 0 && (
              <Flex
                my={{ xs: '20px', md: 0 }}
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <select
                  name="paymentFrequency-summary"
                  id="paymentFrequency-summary"
                  data-id="paymentFrequency-summary"
                  css={paymentFrequencySelect(theme)}
                  value={props?.form?.paymentFrequency}
                  onChange={e =>
                    props?.updateForm('paymentFrequency', e.target.value)
                  }
                >
                  <option value="MONTHLY">per month</option>
                  <option value="QUARTERLY">per quarter</option>
                  <option value="HALF_YEARLY">per 6 months</option>
                  <option value="YEARLY">per year</option>
                </select>
              </Flex>
            )}
          </Flex>
          <Text color="white" fontSize={1} mb={{ xs: 3, lg: 0 }}>
            Includes a {props?.tierName} rebate of{' '}
            <strong>
              <NumberFormat
                value={props?.tierPercentage}
                displayType={'text'}
                thousandSeparator={true}
                suffix={'%'}
                decimalScale={3}
              />
            </strong>
          </Text>
        </Box>
        <DiscountBar
          onClick={() => props?.updateForm('paymentFrequency', 'YEARLY')}
          paymentFrequency={props?.form?.paymentFrequency}
          css={css`
            background-color: rgba(255, 255, 255, 0.25);
            height: 48px;
            padding: 0 32px;
          `}
        />
      </TotalTileContainer>
    </Flex>
  );
});

const Actions = React.memo(({ emailQuote = true, ...props }) => {
  const { theme } = useThemeUI();
  const row = css`
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 40px;

    button:first-of-type {
      margin-right: 30px;
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 0;
      width: 100%;

      button,
      a {
        width: 100%;
        margin: 0;
      }

      button:first-of-type {
        margin-right: 0;
        margin-top: 20px;
        order: 2;
      }

      button:last-of-type {
        order: 1;
      }
    }
  `;

  return (
    <div css={row}>
      {emailQuote && (
        <Button
          data-id="email-quote-top"
          variant={theme.emailQuoteButtonStyle}
          onClick={props?.onShowEmail}
          mr={{ xs: 0, md: '15px' }}
          style={{ minWidth: '200px' }}
        >
          Email quote
        </Button>
      )}

      <JoinNowButton
        loading={props?.submittingQuote}
        onClick={props?.onApply}
        text="Apply now"
      />
    </div>
  );
});

const QuoteOverview = ({ prices, freeProductsEnabled = true, ...props }) => {
  const { theme } = useThemeUI();
  const termsAndConditionsPdf = useFiles('hospitalFreeExtrasTermsConditions');
  const variants = props?.selections?.hospital?.product
    ? props?.selections?.hospital?.product?.variants?.filter(v => {
        if (!v.onSale) {
          return false;
        }
        if (v.classes && v.classes.length) {
          return v.classes.includes(props?.form.status.value);
        }

        return true;
      })
    : [];

  const excessVariants = {
    display: variants.length > 0 && variants.some(v => v.onSale && v.excess),
    values: [],
  };

  if (excessVariants.display) {
    excessVariants.values = variants
      .sort((a, b) => {
        if (a.excess < b.excess) {
          return -1;
        }
        if (a.excess > b.excess) {
          return 1;
        }
        return 0;
      })
      .map(v => {
        return {
          label: v.excess ? `$${v.excess}` : '$0',
          value: v.code,
        };
      });
  }

  const totalRef = useRef(null);
  const { width: rateToggleWidth } = useElementWidth(totalRef.current);

  const isMobile = useMobileScreen();
  const isPortrait = usePortrait();

  const handleBeforeRate = () => {
    props?.updateForm('resultType', 'CURRENT');
  };

  const handleAfterRate = () => {
    props?.updateForm('resultType', 'FUTURE');
  };

  if (props?.quote?.loading) {
    return <Loading size="large" />;
  }

  return (
    <div
      css={css`
        width: 100%;
        max-width: 970px;
        margin-left: auto;
        margin-right: auto;
      `}
    >
      <Flex
        flexDirection="row"
        justifyContent={{
          xs: 'center',
          md: 'space-between',
          lg: 'space-between',
          xl: 'space-between',
        }}
        alignItems="center"
        pt={{
          xs: 0,
          md: '40px',
        }}
      >
        <Title className="hide-mobile">Quote summary</Title>
        <RatesToggle
          quoteForm={props?.form}
          selected={props?.form?.resultType}
          onBefore={handleBeforeRate}
          onAfter={handleAfterRate}
          mt={{
            xs: 40,
            md: 0,
          }}
          width={{
            xs: 1,
            md: rateToggleWidth || 350,
          }}
        />
      </Flex>

      <SelectedProducts>
        {props?.selections?.hospital?.product ? (
          <Tile
            type="hospital"
            label="Hospital"
            title={props?.selections?.hospital?.product?.title}
            description={props?.selections?.hospital?.product?.coverage}
            excess={props?.selections?.hospital?.variant?.excess}
            price={prices?.hospital?.net}
            paymentFrequency={props?.form?.paymentFrequency}
            asterisk={true}
            onDetails={props?.onDetails}
            link={`/${props?.selections?.hospital?.product?.id}`}
            freeProductsEnabled={freeProductsEnabled}
          />
        ) : (
          <Tile
            type="hospital"
            label="Hospital"
            title="No hospital cover selected"
            freeProductsEnabled={freeProductsEnabled}
          />
        )}
        <ProductSpacer>
          <Icon name="plus" fill="#1d4b8d" width="24px" height="auto" />
        </ProductSpacer>
        {props?.selections?.extras?.product ? (
          <Tile
            freeProductsEnabled={freeProductsEnabled}
            type="extras"
            label="Extras"
            title={props?.selections?.extras?.product?.title}
            description={props?.selections?.extras?.product?.coverage}
            price={prices?.extras?.net}
            originalPrice={prices?.extras?.original}
            paymentFrequency={props?.form?.paymentFrequency}
            onDetails={props?.onDetails}
            link={`/${props?.selections?.extras?.product?.id}`}
          />
        ) : (
          <Tile
            freeProductsEnabled={freeProductsEnabled}
            type="extras"
            label="Extras"
            title="No extras cover selected"
          />
        )}

        <ProductSpacer>
          {' '}
          <Icon
            name="equals"
            fill="#1d4b8d"
            width="24px"
            height="auto"
            className="hide-mobile"
          />{' '}
        </ProductSpacer>

        <TotalTile
          data-id="product-tile-total"
          ref={totalRef}
          price={prices?.total || 0}
          tierName={
            props?.form?.tier?.value && props?.form?.tier?.value !== '0'
              ? `tier ${props?.form.tier.value}`
              : `base tier`
          }
          variants={excessVariants.values}
          tierPercentage={pathOr(0, 'quote.data.rebatePercentage')(props)}
          selections={props?.selections}
          form={props?.form}
          setSelected={props?.setSelected}
          updateForm={props?.updateForm}
        />
      </SelectedProducts>

      {props?.form?.dob?.age < 30 ? (
        <a
          href="/youth-discount"
          target="_blank"
          style={{
            textDecoration: 'none',
            color: theme.colors.black,
          }}
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            py="14px"
            px={{ xs: '8px', md: '20px' }}
            css={css`
              border: 2px solid ${theme.colors.borders};
            `}
          >
            {
              <Icon
                name="tada"
                width={isMobile && isPortrait ? '64px' : '32px'}
                height={isMobile && isPortrait ? '64px' : '32px'}
                stroke={theme.colors.primary}
              />
            }
            <Small
              css={css`
                margin-left: 20px;
                line-height: 20px;
                font-size: ${theme.fontSizes[2]}px !important;
                font-style: normal;
              `}
            >
              * You're entitled to a{' '}
              <a
                href="/youth-discount"
                target="_blank"
                style={{
                  fontWeight: '600',
                  color: theme.colors.primary,
                  textDecoration: 'none',
                }}
              >
                youth discount
              </a>{' '}
              of{' '}
              <Popover
                position="bottom"
                content="Please call 1800 226 126 to confirm your applicable discount."
              >
                <span
                  style={{
                    color: theme.colors.primary,
                    fontWeight: '600',
                  }}
                >
                  up to 10%
                </span>
              </Popover>{' '}
              of your hospital premium! <br className="shide-mobile" />
              <span
                css={css`
                  font-size: ${theme.fontSizes[0]}px;
                `}
              >
                This discount is not reflected in the quote shown above but will
                be applied once we receive your application.
              </span>
            </Small>
          </Flex>
        </a>
      ) : (
        <Small>
          * This quote does not include any applicable{' '}
          <Popover
            position="bottom"
            content={
              <>
                18 to 29 year-olds are entitled to a discount of up to 10% of
                their hospital insurance premium. Call{' '}
                <CallUsNumber fontSize={14} inline color={theme.colors.white} />{' '}
                to find out if you are eligible for a discount.
              </>
            }
          >
            <span style={{ color: theme.colors.primary }}>
              {' '}
              youth discount{' '}
            </span>
          </Popover>{' '}
          or
          <Popover
            position="bottom"
            content="A 2% Lifetime Health Cover (LHC) loading is added to your hospital premium for every year you didn’t hold hospital cover after you turn 31. If you do not know your applicable loading %, this will be applied automatically once we process your application."
          >
            <span style={{ color: theme.colors.primary }}>
              {' '}
              lifetime heath cover{' '}
            </span>
          </Popover>{' '}
          loading and may differ from your final premium.
          {props?.isLec && (
            <>
              The Extras component of your policy is fully subsided by Avant for
              a two-year period. This is based on your continued membership with
              Avant during this time.{' '}
              <Link to={termsAndConditionsPdf?.url} external>
                Click here for full Terms & Conditions.{' '}
              </Link>
            </>
          )}
        </Small>
      )}

      <Flex
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'space-around' }}
        alignItems="center"
      >
        <Actions
          emailQuote={props?.emailQuote}
          submittingQuote={props?.submittingQuote}
          onShowEmail={props?.onShowEmail}
          onApply={props?.onApply}
          form={props?.form}
        />
      </Flex>
    </div>
  );
};

export default QuoteOverview;
